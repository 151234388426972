//ANIMATE GALLERY AND SHOP ON LOAD-------------------------------------------------------

var gallery = document.querySelectorAll(".gallery img");
var timer = 1;
function reveal (art){
    setTimeout(function(){art.classList.add("visible")}, 50*timer)
    timer++;
}
Array.prototype.find.call(gallery,reveal) 


var igallery = document.querySelectorAll(".ipad-gallery img");
var timer = 1;
function reveal (art){
    setTimeout(function(){art.classList.add("visible")}, 30*timer)
    timer++;
}
Array.prototype.find.call(igallery,reveal) 


var shop = document.querySelectorAll(".shop-gallery img");
var timer = 1;
function reveal (art){
    setTimeout(function(){art.classList.add("visible")}, 60*timer)
    timer++;
}
Array.prototype.find.call(shop,reveal) 

var ipadshop = document.querySelectorAll(".ipad-shop-gallery img");
var timer = 1;
function reveal (art){
    setTimeout(function(){art.classList.add("visible")}, 60*timer)
    timer++;
}
Array.prototype.find.call(ipadshop,reveal) 


/*  Filter desktop gallery ---------------------------------------------------------*/

const listItems = document.querySelectorAll('.dropdown-container a');
const allimages = document.querySelectorAll('.gallery .galleryimg');


 
function toggleActiveClass(active){
    listItems.forEach(item => {
      item.classList.remove('active');
    })
    active.classList.add('active');
}
 
function toggleimages(dataClass){
    if(dataClass === 'all'){
        for(let i = 0; i<allimages.length; i++){
            allimages[i].style.display = 'block';
        }
    }else{
        for(let i = 0; i<allimages.length; i++)
            allimages[i].dataset.class === dataClass ? allimages[i].style.display = 'block' : allimages[i].style.display = 'none';
    }
}
 
for(let i = 0; i < listItems.length; i++){
    listItems[i].addEventListener('click', function(){
        toggleActiveClass(listItems[i]);
        toggleimages(listItems[i].dataset.class);
    });
}
// IPAD GAllery filter

const ipadlistItems = document.querySelectorAll('.ipad-dropdown-container a');
const ipadallimages = document.querySelectorAll('.ipad-gallery .galleryimg');


 
function itoggleActiveClass(active){
    ipadlistItems.forEach(item => {
      item.classList.remove('active');
    })
    active.classList.add('active');
}
 
function itoggleimages(dataClass){
    if(dataClass === 'all'){
        for(let i = 0; i<ipadallimages.length; i++){
            ipadallimages[i].style.display = 'block';
        }
    }else{
        for(let i = 0; i<ipadallimages.length; i++)
        ipadallimages[i].dataset.class === dataClass ? ipadallimages[i].style.display = 'block' : ipadallimages[i].style.display = 'none';
    }
}
 
for(let i = 0; i < ipadlistItems.length; i++){
    ipadlistItems[i].addEventListener('click', function(){
        itoggleActiveClass(ipadlistItems[i]);
        itoggleimages(ipadlistItems[i].dataset.class);
    });
}

// Mobile GAllery filter

const MlistItems = document.querySelectorAll('.dropdown-content-submenu a');
const Mallimages = document.querySelectorAll('.mobile-gallery .galleryimg');


 
function MtoggleActiveClass(active){
    MlistItems.forEach(item => {
      item.classList.remove('active');
    })
    active.classList.add('active');
}
 
function Mtoggleimages(dataClass){
    if(dataClass === 'all'){
        for(let i = 0; i<allimages.length; i++){
            Mallimages[i].style.display = 'block';
        }
    }else{
        for(let i = 0; i<Mallimages.length; i++)
            Mallimages[i].dataset.class === dataClass ? Mallimages[i].style.display = 'block' : Mallimages[i].style.display = 'none';
    }
}
 
for(let i = 0; i < MlistItems.length; i++){
    MlistItems[i].addEventListener('click', function(){
        MtoggleActiveClass(MlistItems[i]);
        Mtoggleimages(MlistItems[i].dataset.class);
    });
}